import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../components/FormInput/FormInputText";
import { FormInputRadio } from "../../components/FormInput/FormInputRadio";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Divider,
  Paper,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { POST_LOAN_DETAILS } from "../../../src/services/CONSTANTS";
import { POSTLOANDETAILS } from "../../../src/services/loanLookupService";
import Header from "../CommonComponents/Header";
import Footer from "../CommonComponents/Footer";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import abanumber from "../../assets/images/abanumberfinder.png";
const schema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required"),
    //.matches(/^[A-Za-z\s]*$/, "Please a enter valid first name")
    //.matches(/^[A-Za-z]+(-[A-Za-z]+)*$/, "Please a enter valid first name")
    
    //.min(3, "First Name length should be at least 3 characters"),
  last_name: Yup.string()
    .required("Last name is required"),
    //.matches(/^[A-Za-z\s]*$/, "Please a enter valid last name")
    //.matches(/^[A-Za-z]+(-[A-Za-z]+)*$/, "Please a enter valid last name")
    //.min(3, "Last name length should be at least 3 characters"),
  accountholdername: Yup.string()
    .required("account holder's name  is required")
    .matches(/^[A-Za-z\s]*$/, "Please enter valid account holder's name "),
  email: Yup.string().email().required(),
  address1: Yup.string().required("Address1 is required"),
  city: Yup.string()
    .required("City is required"),
    //.matches(/^[A-Za-z\s]*$/, "Please enter valid city "),
  state: Yup.string()
    .required("State is required"),
    //.matches(/^[A-Z]*$/, "Please enter valid State "),
  zip: Yup.string()
    .required("zip is required"),
    //.matches(RegExp("^[0-9-( )]+([0-9]+)*$"), "Please enter valid zip"),
  amount: Yup.string()
    .required("Payment Amount is required")
    .matches(
      /^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d+)?$/,
      "Please enter a valid Payment Amount"
    ),

  checkaba: Yup.string()
    .min(9, "ABA Routing number should be at least 9 digits")
    .max(9, "ABA Routing number should be at least 9 digits")
    .required("ABA Routing number is required")
    .matches(
      RegExp("^[0-9]+[0-9]*$"),
      "Please enter a valid ABA Routing number"
    ),
  checkaccountno: Yup.string()
    .required("Account Number is required")
    .matches(RegExp("^[0-9]+[0-9]*$"), "Please enter a valid Account Number"),
  confirmaccountnumber: Yup.string()
    .required("Confirm Account Number is required")
    .oneOf([Yup.ref("checkaccountno")], "Account Number must match"),
  confirmaba: Yup.string()
    .required("Confirm ABA Routing number is required")
    .oneOf([Yup.ref("checkaba")], "ABA Routing number must match"),
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Paymentform(details) {
  let history = useHistory();
  const classes = useStyles();
  var Loandata = details.location.loanDetails;
  console.log("Paymentform props", Loandata);
  const [account_type, setAccount_type] = useState("checking");

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: Loandata.borrowerName.split(",")[1].trim(),
      last_name: Loandata.borrowerName.split(",")[0],
      ponumber: Loandata.loanNumber.toString() + (Math.floor((Math.random() * 10) + 1)).toString(),
      amount: Number(Loandata.amountDue).toFixed(2).toLocaleString("en-us"),
      accountholdername:
        Loandata.borrowerName.split(",")[1].trim() +
        " " +
        Loandata.borrowerName.split(",")[0],
      address1: Loandata.address,
      address2: "",
      city: Loandata.city,
      state: Loandata.state,
      zip: Loandata.zipcode,
      email: Loandata.borrowerEmail,
      checkaba: "",
      checkaccountno: "",
      account_type: account_type,
      optional: "",
      servicingStatus: Loandata.serviceStatus,
      firstPaymentDueDate: Loandata.firstDueDate,
      servicingNextDueDate: Loandata.nextDueDate,
      loanNumber: Loandata.loanNumber
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const swalWithBootstrapButtons = Swal.mixin({
    // customClass: {
    //     confirmButton: 'btn btn-success',
    //     cancelButton: 'btn btn-danger'
    // },
    // buttonsStyling: false
  });

  const HandleGoBack = () => {
    history.goBack();
  };
  const onSubmit = async (data) => {
    console.log("data", data);
    const paymentDto = {
      first_name: String(data.first_name),
      last_name: String(data.last_name),
      ponumber: String(data.ponumber),
      loanNumber: String(data.loanNumber),
      amount: parseFloat(data.amount.replace(/[^\d.-]/g, "")),
      merchant_defined_field_1: String(data.address1),
      checkname: String(data.accountholdername),
      address1: String(data.address1),
      address2: String(data.address2),
      city: String(data.city),
      state: String(data.state),
      zip: String(data.zip),
      email: String(data.email),
      checkaba: String(data.checkaba),
      checkaccount: String(data.checkaccountno),
      account_type: data.account_type,
      servicingstatus: Loandata.serviceStatus,
      firstPaymentDueDate: Loandata.firstDueDate,
      servicingNextDueDate: Loandata.nextDueDate,
      servicerName: Loandata.servicerName,
      customerServiceNumber: Loandata.servicerCustomerCareNumber,
    };

    console.log(paymentDto, "paymentDto");
    const url = POST_LOAN_DETAILS();

    // var paymentdata = ` Amount - $${data.amount}\n, ABA Routing number - ${data.checkaba} \n, Account Number - ${data.checkaccountno}  `;
    var str =
      "Amount: " +
      data.amount +
      "\n" +
      "ABA Routing number: " +
      data.checkaba +
      "\n" +
      "Account Number: " +
      data.checkaccountno +
      "\n";
    // "Task2NameLonger    :    Failed   :   statusCode\n";

    swalWithBootstrapButtons
      .fire({
        title: `Payment`,
        html: "<pre>" + str + "</pre>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, make it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(url, paymentDto) //, config)
            .then(function (response) {
              if (response.status === 200) {
                var responseText = response.data.data.split("&")[1];
                console.log(responseText, "responseText");
                if (response.data.data.includes("SUCCESS") || response.data.data.includes("APPROVED")) {
                  history.push({
                    pathname: "/paymentresult",
                    state: {
                      responseDetails: [
                        response.data.data,
                        data.amount,
                        data.checkaba,
                        data.checkaccountno,
                      ],
                    },
                  });
                } else {
                  swalWithBootstrapButtons.fire(
                    "Cancelled",
                    "Your payment has been Cancelled - " + responseText,
                    "error"
                  );
                }
              } else {
                swalWithBootstrapButtons.fire(
                  "Cancelled",
                  "Your payment has been Cancelled ",
                  "error"
                );
              }
            })

            .catch(function (error) {
              Swal.fire("Payment failed!", "", "error");
              console.log(error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your payment has been Cancelled ",
            "error"
          );
        }
      });

    console.log("Data", data);
  };

  return (
    <Fragment>
      <Header />
      <Container maxWidth="lg" style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6} component={Paper}>
                <Grid container spacing={2} className={classes.root}>
                  <Grid item xs={12} sm={12}>
                    <IconButton
                      aria-label="delete"
                      className={classes.margin}
                      onClick={HandleGoBack}
                    >
                      <KeyboardBackspaceIcon />
                    </IconButton>

                    <Typography
                      component="h2"
                      variant="h5"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "15px",
                      }}
                    >
                      Payment details of loan number - {Loandata.loanNumber}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label>
                      <h3>Personal Details:</h3>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormInputText
                      name="first_name"
                      for="first_name"
                      control={control}
                      label="Borrower's First Name"
                      inputRef={register("first_name")}
                      error={Boolean(errors.first_name)}
                      helperText={errors.first_name?.message}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormInputText
                      name="last_name"
                      control={control}
                      label="Borrower's Last Name "
                      inputRef={register("last_name")}
                      error={Boolean(errors.last_name)}
                      helperText={errors.last_name?.message}
                      disabled
                    />
                  </Grid>

                 
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label>
                      <h3>Address:</h3>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormInputText
                      name="address1"
                      control={control}
                      label="Address 1 "
                      inputRef={register("address1")}
                      error={Boolean(errors.address1)}
                      helperText={errors.address1?.message}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormInputText
                      name="address2"
                      control={control}
                      label="Address 2  "
                      inputRef={register("address2")}
                      error={Boolean(errors.address2)}
                      helperText={errors.address2?.message}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormInputText
                      name="city"
                      control={control}
                      label="City "
                      inputRef={register("city")}
                      error={Boolean(errors.city)}
                      helperText={errors.city?.message}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormInputText
                      name="state"
                      control={control}
                      label="State "
                      inputRef={register("state")}
                      error={Boolean(errors.state)}
                      helperText={errors.state?.message}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormInputText
                      name="zip"
                      control={control}
                      label="Zip"
                      inputRef={register("zip")}
                      error={Boolean(errors.zip)}
                      helperText={errors.zip?.message}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label>
                      <h3>Bank Details:</h3>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormInputText
                      name="accountholdername"
                      control={control}
                      label="Account Holder's Name "
                      inputRef={register("accountholdername")}
                      error={Boolean(errors.accountholdername)}
                      helperText={errors.accountholdername?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormInputText
                      name="email"
                      control={control}
                      label="Email (required for receipt)"
                      inputRef={register("email")}
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormInputText
                      name="amount"
                      control={control}
                      label="Payment Amount "
                      inputRef={register("amount")}
                      error={Boolean(errors.amount)}
                      helperText={errors.amount?.message}
                      inputpropdata={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormInputText
                      name="checkaba"
                      control={control}
                      label="ABA Routing number"
                      inputRef={register("checkaba")}
                      error={Boolean(errors.checkaba)}
                      helperText={errors.checkaba?.message}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormInputText
                      name="confirmaba"
                      control={control}
                      label="Confirm ABA Routing number"
                      inputRef={register("confirmaba")}
                      error={Boolean(errors.confirmaba)}
                      helperText={errors.confirmaba?.message}
                      handleCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      handlePaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                  <img src={abanumber} alt="s1llogo" className="s1lLogo" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormInputText
                      name="checkaccountno"
                      control={control}
                      label="Account Number"
                      inputRef={register("checkaccountno")}
                      error={Boolean(errors.checkaccountno)}
                      helperText={errors.checkaccountno?.message}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormInputText
                      name="confirmaccountnumber"
                      control={control}
                      label="Confirm Account Number"
                      inputRef={register("confirmaccountnumber")}
                      error={Boolean(errors.confirmaccountnumber)}
                      helperText={errors.confirmaccountnumber?.message}
                      handleCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      handlePaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputRadio
                      name={"account_type"}
                      control={control}
                      label={""}
                      options={[
                        {
                          label: "Checking",
                          value: "checking",
                        },
                        {
                          label: "Savings",
                          value: "savings",
                        },
                      ]}
                      inputRef={register("account_type")}
                      error={Boolean(errors.account_type)}
                      helperText={errors.account_type?.message}
                      onChange={(e) => setAccount_type(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormLabel component="legend" style={{ marginBottom: 10 }}>
                      Comments (Use this field to denote extra principal or
                      other helpful information about this payment)
                    </FormLabel>
                    <FormInputText
                      name="optionalfield"
                      control={control}
                      label="Optional"
                      rows={4}
                      multiline={true}
                      inputRef={register("optionalfield")}
                      error={Boolean(errors.optionalfield)}
                      helperText={errors.optionalfield?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Container>

      <Footer />
    </Fragment>
  );
}
