import React from "react";
import { Button, Container, Grid, Row } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import * as CurrencyFormat from "react-currency-format";
const LoanDetail = (props) => {
  const { loanData, history, classes, s1l, serviceReleased, serviceRetained } =
    props;
  const { multiple, loanInfo, borrowerInfo } = loanData;

  const RedirectBtn = () => {
    const MakeRedirect = () => {
      window.location.href = "https://s1l.servicingdivision.com/Account/Register";
    };
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={MakeRedirect}
      >
        Register Here for our Online Portal{" "}
      </Button>
    );
  };
  const MakeServiceDueDate = (value) => {

    const serviceDueDate =
      value.val.nextDueDate.slice(0, 10) !== "1900-01-01" ?
        format(new Date(value.val.nextDueDate), "MM/dd/yyyy") : value.val.firstDueDate.slice(0, 10) !== "1900-01-01"
          ? format(new Date(value.val.firstDueDate), "MM/dd/yyyy") : "";
    console.log(value);
    return !value.val.isServicerInformation ? (
      <li>
        Servicing Next Due Date -{" "}
        <span className={classes.loanDetailText}>
          {serviceDueDate}
        </span>{" "}
      </li>
    ) : <></>;
  }
  const MakeAmountDue = (value) => {
    return (<li>
      Amount Due -{" "}
      <span className={classes.loanDetailText}>
        {" "}
        {/* ${Number(value.amountDue).toFixed(2)} */}
        <CurrencyFormat value={Number(value.val.amountDue).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
      </span>
    </li>);
  }
  const MakePaymentbtn = (paymentProps) => {
    const { val } = paymentProps;
    let borrowerObj = Object.assign({}, borrowerInfo);
    let loanObj = Object.assign({}, val);
    loanObj.borrowerName =
      multiple === "borrowers"
        ? borrowerObj[0].borrowerName
        : borrowerObj.borrowerName;
    loanObj.borrowerEmail =
      multiple === "borrowers"
        ? borrowerObj[0].borrowerEmail
        : borrowerObj.borrowerEmail;
    loanObj.address =
      loanObj.address && loanObj.address !== ""
        ? loanObj.address
        : loanObj.address1;
    const MakePaymentHandler = () => {
      history.push({
        pathname: "/Paymentform",
        loanDetails: loanObj,
      });
    };
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={MakePaymentHandler}
        className={classes.marginButton}
      >
        Make a Payment
      </Button>
    );
  };

  const getDates = (val) => {
    console.log(val);
    const dueDate = new Date(val.firstDueDate.slice(0, 10));
    let servicingNextDueDate = new Date(val.nextDueDate.slice(0, 10));
    const firstDueDateInvestor = new Date(val.firstDueDateInvestor.slice(0, 10));
    //console.log(val.nextDueDate.slice(0, 10)); 
    //console.log(val.nextDueDate);
    if (val.nextDueDate === "1900-01-01T00:00:00") {
      servicingNextDueDate = new Date(val.firstDueDate.slice(0, 10));;
    }
    console.log(servicingNextDueDate);
    //console.log(dueDate);
    return {
      dueDate: dueDate,
      firstDueDateInvestor: firstDueDateInvestor,
      servicingNextDueDate: servicingNextDueDate,
    };
  };

  const getDatesWithoverridingDueDate = (val) => {
    //console.log(val);
    const dueDate = new Date(val.firstDueDate.slice(0, 10));
    let servicingNextDueDate = new Date(val.nextDueDate.slice(0, 10));
    const firstDueDateInvestor = new Date(val.firstDueDateInvestor.slice(0, 10));

    //console.log(servicingNextDueDate);
    //console.log(dueDate);
    return {
      dueDate: dueDate,
      firstDueDateInvestor: firstDueDateInvestor,
      servicingNextDueDate: servicingNextDueDate,
    };
  };

  const removeDashfromNumber = (num) => {

    return num === undefined ? "" : num.replace(/[^\d+]/g, "");
  };

  const getServiceNumber = (num) => {
    const number = removeDashfromNumber(num);
    return `tel:+1${number}`;
  };

  const checkShowRedirect = (val) => {
    return val.providerName === s1l && val.serviceStatus === serviceRetained && val.tmsLoanNumber !== "";
  };

  const checkShowTransferProvider = (val) => {
    return (
      val.providerName === s1l &&
      val.serviceStatus === serviceReleased &&
      val.firstDueDate === val.firstDueDateInvestor
    );
  };

  const checkShowborrower = (val) => {
    const { dueDate, servicingNextDueDate, firstDueDateInvestor } =
      getDates(val);
    return (
      val.providerName === s1l &&
      val.serviceStatus === serviceReleased &&
      dueDate === firstDueDateInvestor
      //&&
      //servicingNextDueDate === firstDueDateInvestor
      //ServicingNextDueDate -- 3514
      //DueDate --- 682
    );
  };

  const checkShowPaymentBtn = (val) => {
    const { dueDate, servicingNextDueDate, firstDueDateInvestor } =
      getDates(val);
    console.log(val);
    return (
      val.providerName === s1l &&
      val.serviceStatus === serviceReleased &&
      dueDate < firstDueDateInvestor &&
      (servicingNextDueDate < firstDueDateInvestor ||
        val.nextDueDate === "1900-01-01T00:00:00")
    );
  };
  const checkShowPaymentBtn_StatusIsNull = (val) => {
    const { dueDate, servicingNextDueDate, firstDueDateInvestor } =
      getDates(val);
    return (
      val.providerName === s1l &&
      val.serviceStatus === null || val.serviceStatus === ""
    );
  };
  const checkShowPaymentBtn_BlankNextDueDate = (val) => {
    const { servicingNextDueDate } =
      getDatesWithoverridingDueDate(val);
    console.log("due Date : ", servicingNextDueDate);
    return (
      val.providerName === s1l &&
      val.serviceStatus === serviceReleased &&
      val.nextDueDate === "1900-01-01T00:00:00"
    );
  };
  const checkShowPaymentBtn_TMSBlank = (val) => {

    return (
      val.providerName === s1l &&
      val.serviceStatus === serviceRetained &&
      val.tmsLoanNumber === ""
    );
  };
  const checkShowRedirectBtn = (val) => {
    return val.providerName === s1l && val.serviceStatus === serviceRetained && val.tmsLoanNumber !== "";
  };

  const renderRedirect = (val) => {

    const serviceNumber = getServiceNumber(val.servicerCustomerCareNumber);
    return val.isRedirectButton ? (
      <div>
        Please visit us at{" "}
        <a
          href="https://s1l.servicingdivision.com"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#fab900", fontSize: "16px" }}
          className={classes.removeLink}
        >
          s1l.servicingdivision.com{" "}
        </a>{" "}
        or contact{" "}
        <a
          href={serviceNumber}
          target="_blank"
          className={classes.removeLink}
          rel="noreferrer"
          style={{ color: "#fab900", fontSize: "16px" }}
        >
          {" "}
          {val.servicerCustomerCareNumber}{" "}
        </a>{" "}
      </div>
    ) : null;
  };

  const renderBorrower = (val) => {
    const serviceNumber = getServiceNumber(val.servicerCustomerCareNumber);
    return val.isServicerInformation ? (
      <>
        No payment due, This loan has been transferred to {val.servicerName}
        contact customer service number
        <a
          href={serviceNumber}
          target="_blank"
          rel="noreferrer"
          className={classes.removeLink}
        >
          {" "}
          {val.servicerCustomerCareNumber}{" "}
        </a>{" "}
      </>
    ) : null;
  };

  const renderTransferProvider = (val) => {
    if (multiple === "loans") {
      return val.length > 0
        ? val.length === 1
          ? val.map((value, index) => {
            return getTransferCard(value, index);
          }) : null
        : null;
    } else {
      return getTransferCard(val, 0);
    }

  };
  const renderTransferProviderMiltiple = (val, index) => {
    if (multiple === "loans") {
      return val.length > 1
        ? getTransferCard(val[index], index)
        : null;
    } else {
      <></>
    }

  };
  const getErrorMessageCard = () => {
    return (
      <div style={{
        fontSize: "20px",
      }}
      >
        Sorry! Something went wrong. Please contact Customer Service at 888-698-0511
      </div>
    );
  };

  const renderErrorMessage = (val, index) => {
    if (multiple === "loans") {
      return val.length > 1
        ? getErrorMessageCard()
        : getErrorMessageCard();
    } else {
      getErrorMessageCard()
    }
  };
  const getTransferCard = (val, index) => {

    const serviceNumber = getServiceNumber(val.servicerCustomerCareNumber);
    return val.isServicerInformation ? (
      <>
        <div style={{ fontSize: "26px" }}>
          This loan has been <span style={{ color: "#fff" }}>transferred</span> to {val.servicerName}.{" "}
          <span style={{ fontSize: "22px" }}>
            <br></br>Contact Customer Service:
            <a
              href={serviceNumber}
              target="_blank"
              rel="noreferrer"
              className={classes.removeLink}
            >
              {" "}
              {val.servicerCustomerCareNumber}{" "}
            </a>{" "}     </span>
        </div>

        {renderTransferredMsg(val)}
      </>
    ) : (
      <></>
    );
  }


  const renderTransferredMsg = (val) => {
    return val.providerName === s1l ? (
      <></>
    ) : (
      <div> This loan has been transferred</div>
    );
  };

  const renderRedirectBtn = (val) => {
    return val.isRedirectButton ? <RedirectBtn /> : <></>;
  };

  const renderPaymentBtn = (val) => {
    return val.isPaymentButton ? <MakePaymentbtn val={val} /> : <></>;
  };
  const renderServiceDueDate = (val) => {
    return val.isServiceDueDate ? <MakeServiceDueDate val={val} /> : <></>;
  };
  const renderAmountDue = (val) => {
    return !val.isRedirectButton ? <MakeAmountDue val={val} /> : <></>;
  };
  const renderPaymentBtn_blankduedate = (val) => {
    return checkShowPaymentBtn_BlankNextDueDate(val) ? <MakePaymentbtn val={val} /> : <></>;
  };
  const renderPaymentBtn_StatusIsNull = (val) => {
    return checkShowPaymentBtn_StatusIsNull(val) ? <MakePaymentbtn val={val} /> : <></>;
  };
  const renderPaymentBtn_TMSBlank = (val) => {
    return checkShowPaymentBtn_TMSBlank(val) ? <MakePaymentbtn val={val} /> : <></>;
  };
  const borrowerCard = (value, index) => {
    return (
      <Card
        style={{ marginBottom: "10px" }}
        className={classes.root}
        key={index}
      >
        <CardContent>
          <Typography component="h2">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  margin: "10px 20px",
                }}
              >
                <AccountCircleIcon style={{ fontSize: '4.5rem' }}></AccountCircleIcon>
              </div>
              <div style={{ marginTop: "2%" }}>
                <span className={classes.userDetailText}>
                  {value.borrowerName.split(",")[1] +
                    " " +
                    value.borrowerName.split(",")[0]}
                </span>
                <br />
                <span className={classes.userEmailText}>
                  {value.borrowerEmail}
                </span>
              </div>
            </div>
          </Typography>
          <Typography
            className={classes.pos}
            color="textSecondary"
          ></Typography>
        </CardContent>
      </Card>
    );
  };

  const renderBorrowerCard = (borrowerData) => {

    if (multiple === "borrowers") {
      return borrowerData.length > 0
        ? borrowerData.map((value, index) => {
          return borrowerCard(value, index);
        })
        : null;
    } else {
      return borrowerCard(borrowerData, 0);
    }
  };
  const rendarTMLoanNumber = (tmsLoanNumber) => {
    return (
      <li style={{ fontWeight: 'bold' }}>
        Use New Servicing Loan Number -{" "}
        <span className={classes.loanDetailText}>
          {tmsLoanNumber}
        </span>
      </li>
    );
  };
  const loanCard = (allloans, value, index) => {
    return (
      <Card
        style={{ marginBottom: "20px" }}
        className={classes.root}
        key={index}
      >
        <CardContent style={{ background: "#fab9003d" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                borderRight: "1px solid black",
                padding: "0px 10px 10px 0",
                minWidth: "50%",
              }}
            >
              <ul>
                <li>
                  Loan Number -{" "}
                  <span className={classes.loanDetailText}>
                    {value.loanNumber}
                  </span>
                </li>
                {value.tmsLoanNumber != "" && rendarTMLoanNumber(value.tmsLoanNumber)}
                <li>
                  Original Loan Amount -{" "}
                  <span className={classes.loanDetailText}>
                    {/* ${Number(value.loanAmount).toFixed(2)} */}
                    <CurrencyFormat value={Number(value.loanAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                  </span>
                </li>
                
                {renderAmountDue(value)}
                {/* if(value.isServiceDueDate !== false)
                {
                  <li>
                    Servicing Next Due Date -{" "}
                    <span className={classes.loanDetailText}>

                      {value.nextDueDate.slice(0, 10) !== "1900-01-01" ?
                        format(new Date(value.nextDueDate), "MM/dd/yyyy") : value.firstDueDate.slice(0, 10) !== "1900-01-01" ? format(new Date(value.firstDueDate), "MM/dd/yyyy") : ""}
                    </span>{" "}
                  </li>
                } : <></> */}
                {renderServiceDueDate(value)}
              </ul>
            </div>
            <div style={{ padding: "20px 10px 10px 30px" }}>
              <span className={classes.loanDetailText}>
                {value.address && value.address !== ""
                  ? value.address
                  : value.address1}
              </span>
              <br />
              <span className={classes.loanDetailText}>{value.city}</span>
              {", "}
              <span className={classes.loanDetailText}>{value.state}</span>
              {", "}
              <span className={classes.loanDetailText}>{value.zipcode}</span>
            </div>
          </div>
        </CardContent>
        <CardActions
          style={{
            backgroundColor: "#363636",
            fontSize: "14px",
            color: "#fab900",
          }}
        >
          {renderBottomInfo(allloans, value, index)}

        </CardActions>
      </Card>
    );
  };
  const renderBottomInfo = (allloans, value, index) => {

    if (multiple !== "loans") {
      return (
        <> <div>{renderRedirect(value)}</div>
          {/* <div>{renderBorrower(value)}</div> */}
          {renderTransferProviderMiltiple(allloans, index)}
          {/* {renderErrorMessage(allloans, index)} */}
          <div style={{ display: "block", textAlign: "right" }}>
            {renderRedirectBtn(value)}
            {renderPaymentBtn(value)}
          </div></>
      );
    } else {
      return (<>
        <div>{!allloans[index].isErrorShow ? renderRedirect(value) : ""}</div>
        {/* <div>{renderBorrower(value)}</div> */}
        {!allloans[index].isErrorShow ? renderTransferProviderMiltiple(allloans, index) : ""}
        {allloans[index].isErrorShow ? renderErrorMessage(allloans, index) : ""}
        <div style={{ display: "block", textAlign: "right" }}>
          {!allloans[index].isErrorShow ? renderRedirectBtn(value) : ""}
          {!allloans[index].isErrorShow ? renderPaymentBtn(value) : ""}
        </div>
      </>);
    }
  };
  const renderLoanCard = (loanData) => {
    if (multiple === "loans") {
      return loanData.length > 0
        ? loanData.map((value, index) => {
          return loanCard(loanData, value, index);
        })
        : null;
    } else {
      return loanCard(loanData, loanData, 0);
    }
  };
  const HandleGoBack = () => {
    history.goBack();
  };
  return (
    <div>
      <div style={{ backgroundColor: "#fab900", textAlign: "center" }}>
        <Grid container spacing={0}>
          <Grid item sx={{ mt: 2, xs: 1 }}>
            <IconButton
              aria-label="Back to home"
              className={classes.margin}
              onClick={HandleGoBack}
              title="Back to Home"
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <h2 style={{ color: "#fff" }}>Loan Lookup Details</h2>
          </Grid>
        </Grid>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
            </div>
            <div className="col-md-9">

            </div>
          </div>
        </div>
      </div>
      <Card>
        <CardActions
          style={{
            backgroundColor: "#363636",
            color: "#fab900",
          }}
        >

          {renderTransferProvider(loanInfo)}

        </CardActions>
      </Card>
      <h3>Borrowers:</h3>
      {renderBorrowerCard(borrowerInfo)}
      <h3 style={{ marginTop: "5%" }}>Loan Details:</h3>
      <div>{renderLoanCard(loanInfo)}</div>
    </div>
  );
};

export default LoanDetail;
