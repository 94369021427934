import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const LoansByBorrowerForm = (props) => {
  const { classes, register, errors } = props;

  return (
    <form className={classes.form} noValidate>
      <TextField
        {...register("lastName", {
          required: "Last Name is required.",
        })}
        error={Boolean(errors.lastName)}
        helperText={errors.lastName?.message}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="lastName"
        autoFocus
      />
      <TextField
        {...register("borrowerDOB", {
          required: "Date of birth is required.",
        })}
        error={Boolean(errors.borrowerDOB)}
        helperText={errors.borrowerDOB?.message}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="borrowerDOB"
        label="Date Of Birth (MM/DD/YYYY)"
        name="borrowerDOB"
        autoComplete="borrowerDOB"
        autoFocus
      />
      <TextField
        {...register("ssn", {
          required: "Last four digits of SSN is required.",
        })}
        error={Boolean(errors.ssn)}
        helperText={errors.ssn?.message}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="ssn"
        label="Last four digits of SSN"
        type="text"
        id="ssn"
        autoComplete="Last four digits of SSN"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Look up
      </Button>
    </form>
  );
};

export default LoansByBorrowerForm;
