import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import "./Footer.css";
import logosm from "./../../assets/images/logosm.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#363636",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  spamcolor: {
    color: "#fff",
  },
  divSpacing: {
    lineHeight: "1rem",
    paddingTop: "1.5rem",
  },
}));

export default function FooterNew() {
  const classes = useStyles();
  return (
    <div className="footer">
      <Fragment>
        <CssBaseline />
        <Grid container>
          <Grid item xs={3}>
            <div className="footer-left">
              <img src="/logo.png" alt="" className="logo" />
              <img src="/logosmall.png" style={{display: 'none'}} alt="" className="logo-small" />
            </div>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={0}>
              <Grid item xs={9}>
                <Paper className={classes.paper}>
                  <div className={classes.spamcolor}>
                    {" "}
                    Synergy One Lending, Inc. © 2022 |{" "}
                    <a href="/privacy-policy/" target="_blank" rel="noopener">
                      Privacy Policy
                    </a>{" "}
                    |{" "}
                    <a href="/licensing/" target="_blank" rel="noopener">
                      Licensing
                    </a>
                  </div>
                  <div className={classes.divSpacing}></div>{" "}
                  <div className={classes.spamcolor}>
                    <a
                      href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1907235"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      NMLS Consumer Access
                    </a>{" "}
                    |{" "}
                    <a
                      title="Download Equal Employment Opportunity pdf"
                      href="https://www.dol.gov/ofccp/regs/compliance/posters/pdf/eeopost.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Synergy One Lending is an Equal Opportunity Employer EEO
                      is the law.
                    </a>
                  </div>
                  <div className={classes.divSpacing}></div>{" "}
                  <div className={classes.spamcolor}>
                  For queries please contact Customer Service at 888-698-0511 or <a
                      href="mailto:servicing@s1l.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      servicing@s1l.com
                    </a>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.paper}>
                  <img
                    className="alignnone size-full wp-image-1785 rightlendinglogo"
                    src="/Equal_Housing_Lender.png"
                    alt="Equal Housing Lender logo"
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    </div>
  );
}
